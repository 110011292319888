<template>
    <div class="slider"
         @mousedown="dragStart"
         @mousemove="drag"
         @mouseup="dragEnd"
         @mouseleave="dragEnd"
         @touchstart="dragStart"
         @touchmove="drag"
         @touchend="dragEnd"
         @click="clickMove">
        <div class="slider-track"></div>
        <div class="slider-handle" :style="{ left: `${value}%` }"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dragging: false,
            value: 50, // 초기 값
            animationFrameId: null,
        };
    },
    methods: {
        dragStart(event) {
            event.preventDefault();
            this.dragging = true;
            document.addEventListener('mousemove', this.drag);
            document.addEventListener('mouseup', this.dragEnd);
            document.addEventListener('touchmove', this.drag);
            document.addEventListener('touchend', this.dragEnd);
        },
        drag(event) {
            if (!this.dragging || this.animationFrameId) return;
            this.animationFrameId = requestAnimationFrame(() => {
                const rect = this.$el.getBoundingClientRect();
                const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
                const x = clientX - rect.left; // x position within the element.
                const width = rect.right - rect.left;
                this.value = (x / width) * 100;
                this.$emit('input', this.value); // 이벤트 발생
                this.animationFrameId = null;
            });
        },
        dragEnd() {
            this.dragging = false;
            document.removeEventListener('mousemove', this.drag);
            document.removeEventListener('mouseup', this.dragEnd);
            document.removeEventListener('touchmove', this.drag);
            document.removeEventListener('touchend', this.dragEnd);
            if (this.animationFrameId) {
                cancelAnimationFrame(this.animationFrameId);
                this.animationFrameId = null;
            }
        },
        clickMove(event) {
            const rect = this.$el.getBoundingClientRect();
            const x = event.clientX - rect.left; // x position within the element.
            const width = rect.right - rect.left;
            this.value = (x / width) * 100;
            this.$emit('input', this.value); // 이벤트 발생
        },
    },
};
</script>

<style scoped>
.slider {
    position: relative;
    width: 100%;
    height: 10px;
    background: #ddd;
}
.slider-track {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #bbb;
}
.slider-handle {
    position: absolute;
    left: 50%; /* 초기 위치 */
    top: 50%;
    width: 20px;
    height: 20px;
    background: #888;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
</style>