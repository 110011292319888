<template>
    <div class="room bg-gray-100 p-6" v-if="isMobile">
        <div class="flex items-center">
            <h1 class="text-4xl font-bold mr-4">HMT RTC</h1>
            <button v-on:click="reset" class="py-1 px-2 bg-gray-500 text-white rounded hover:bg-gray-700">
                <i class="fas fa-sync"></i>
            </button>
        </div>
        <div class="config-area bg-white p-4 rounded shadow">
            <ul class="audio-select flex space-x-4">
                <li>
                    <label for="audioInput" class="block text-sm font-medium text-gray-700">Input</label>
                    <select id="audioInput" v-model="audioInputDevice" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option v-for="option in audioInputDevList"
                                :key="option.value"
                                :value="option.value">{{ option.text }}
                        </option>
                    </select>
                </li>
                <li>
                    <label for="audioOutput" class="block text-sm font-medium text-gray-700">Output</label>
                    <select id="audioOutput" v-model="audioOutputDevice" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option v-for="option in audioOutputDevList"
                                :key="option.value"
                                :value="option.value">{{ option.text }}
                        </option>
                    </select>
                </li>
            </ul>
        </div>
        <Call
            ref="call"
            :audio-input-device="audioInputDevice"
            :audio-output-device="audioOutputDevice"
            @writeLog="writeLog">
        </Call>
        <div class="log-area bg-white p-4 rounded shadow mt-4 text-sm">
            <h2 class="text-base font-bold mb-2">Log Window</h2>
            <ul>
                <li v-for="(log, idx) in logList" :key="idx" class="text-gray-700">{{ log }}</li>
            </ul>
        </div>
    </div>
    <div v-else>This page cannot be used on PC. Please use a mobile device.</div>
</template>

<style scoped>
div.config-area {
    display: none;
}
</style>
<script>
import Call from '@/components/Call'
// import Recorder from '@/components/Recorder'

export default {
    name: 'Room',
    components: {
        Call,
        // Recorder,
    },
    data: function() {
        return {
            logList: [],
            audioInputDevList: [],
            audioOutputDevList: [],
            audioInputDevice: '',
            audioOutputDevice: '',
            isMobile: false,
        }
    },
    mounted: function() {
        // 모바일 체크
        this.checkMobile();
        // 브라우처 체크하고
        this.checkBrowser();
        // 오디오 목록 가져오기
        this.getAudioDeviceList();
    },
    methods: {
        checkMobile: function() {
            var UserAgent = navigator.userAgent;
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(UserAgent)) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        reset: function() {
            // reload page
            location.reload();
        },
        checkBrowser: function() {
            var UserAgent = navigator.userAgent;
            if(UserAgent.match(/KAKAOTALK/i)) {
                location.href = 'intent://' +
                    location.href.replace(location.protocol+'//', '') +
                    '#Intent;scheme=https;package=com.android.chrome;end'
            }
        },
        // 로그 기록
        writeLog: function(logText) {
            this.logList.push(logText);
        },
        // 사용가능한 오디오 디바이스 목록 가져오기(In/Out 모두)
        getAudioDeviceList: function() {
            navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
                // 디바이스들 콤보박스에 추가하고
                for (var i = 0; i !== deviceInfos.length; ++i)
                {
                    const deviceInfo = deviceInfos[i];
                    let option = {
                        value: deviceInfo.deviceId,
                        text: ""
                    };
                    if (deviceInfo.kind === 'audioinput')
                    {
                        option.text =  deviceInfo.label ||
                            'Microphone ' + (this.audioInputDevList.length + 1);
                        this.audioInputDevList.push(option);
                    }
                    else if (deviceInfo.kind === 'audiooutput')
                    {
                        option.text = deviceInfo.label ||
                            'Speaker ' + (this.audioOutputDevList.length + 1);
                        this.audioOutputDevList.push(option);
                    }
                }
                // 제일 위의 항목 선택
                if(deviceInfos.length > 0)
                {
                    this.audioInputDevice = this.audioInputDevList[0].value;
                    this.audioOutputDevice = this.audioOutputDevList[0].value;
                }
            })
            .catch((error) => this.writeLog(error));
        },
        // 스트림데이터 연결
        getStream: function() {
            this.$refs.call.getStream();
            // this.$refs.recorder.startRecorder();
        }
    }

}
</script>
