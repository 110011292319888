<template>
    <div v-if="show" class="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
        <div class="fixed inset-0 bg-black opacity-50"></div>
        <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left whitespace-pre-line">
                    <p class="text-lg leading-6">{{ content }}</p>
                    <button class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm" @click="$emit('close')">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show', 'content']
}
</script>