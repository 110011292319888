<template>
    <div class="audio-area bg-gray-100">
        <ul class="space-y-4">
            <li v-for="(src, key) in audioSrcList" :key="key" class="bg-white p-4 rounded shadow">
                <audio :srcObject.prop="src" autoplay></audio>
            </li>
        </ul>
        <div class="form-area bg-white p-4 rounded shadow mt-4">
            <label for="roomName" class="block text-sm font-medium text-gray-700">Room Name</label>
            <input id="roomName" v-model="roomName" :readonly="isStarted" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" @keyup.enter="handleEnterRoomName">
            <label for="userId" class="block text-sm font-medium text-gray-700 mt-4">User Name</label>
            <input id="userId" v-model="userId" :readonly="isStarted" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" @keyup.enter="handleEnterUserId">
            <label for="volume" class="block text-sm font-medium text-gray-700 mt-4 hidden">Rec Volume</label>
            <RTCSlider @input="handleSliderValueChange" class="mt-3 h-10 w-full hidden"/>
            <div id="waveform" class="mt-4"></div>
            <div class="button-area flex space-x-4 mt-6">
                <button v-on:click="join" :disabled="isStarted" class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700" :class="{'opacity-50 cursor-not-allowed': isStarted}">Join</button>
                <button v-on:click="leave" :disabled="!isStarted" class="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-700" :class="{'opacity-50 cursor-not-allowed': !isStarted}">Save</button>
            </div>
            <div class="timer-area mt-4">
                <p>Rec Elapsed Time:
                    <span v-if="Math.floor(recordingTime / 3600) > 0">{{ Math.floor(recordingTime / 3600) }}h</span>
                    <span v-if="Math.floor((recordingTime % 3600) / 60) > 0">{{ Math.floor((recordingTime % 3600) / 60) }}m</span>
                    {{ recordingTime % 60 }}s
                </p>
                <p class="start-timestamp">
                    <span v-if="startTimestamp">
                        Wave File:
                        <a v-if="isLinkActive" :href="waveFileLink">{{ userId }}_{{ startTimestamp }}.webm</a>
                        <span v-else>{{ userId }}_{{ startTimestamp }}.webm</span>
                    </span>
                </p>
            </div>
        </div>
        <Modal :show="showModal" :content="errMsg" @close="showModal = false" />
    </div>
</template>

<script>
import io from 'socket.io-client'
// import axios from "axios";
import RTCSlider from './RTCSlider'
import Modal from "./Modal";

const NoSleep = window.NoSleep;
// const eruda = window.eruda;

export default {
    name: 'Call',
    props: {
        audioInputDevice: String,
        audioOutputDevice: String,
    },
    components: {
        RTCSlider,
        Modal,
    },
    data: function() {
        return {
            socket: null,
            localStream: null,
            peerMap: new Map(),
            audioSrcList: [],
            roomName: this.$debug ? "1111" : "",
            userId: this.$debug ? "test" : "",
            recordAudio: null,
            audioContext: null,
            analyser: null,
            dataArray: null,
            animationFrameId: null,
            rec: null,
            input: null,
            chunks: [],
            timeCode: 0,
            isInitiator: false,
            isChannelReady: false,
            isStarted: false,
            isRecording: false,
            volume: 0.5,
            recStartTime: null,
            recordingTime: 0,
            recordingTimer: null,
            startTimestamp: "",
            showModal: false,
            isLinkActive: false,
            waveFileLink: "",
            noSleep: new NoSleep(),
            disconAlertFlag: false,
            gotStream: false,
            joinFlag: false,
            errMsg: "",
            canvas: null,
            canvasCtx: null,
            mediaRecorder: null,
            baseUrl: this.$debug ? "http://localhost:8080" : "",
            peerConnConfig: {
                'iceServers': [{
                    urls: "stun:record.e-human.co.kr",
                }, {
                    urls: "turn:record.e-human.co.kr",
                    username: "hmt_turn",
                    credential: "hmtTurn!23#"
                }]
            },
        }
    },
    mounted: function() {
        // 권한 요청 후 성공시 디바이스(마이크, 스피커) 권한 얻어오기
        // this.getUserMedia(() => {
        // });
        // this.getStream();
        // eruda.init();


        // 캔버스 생성
        this.canvas = document.createElement('canvas');
        document.getElementById('waveform').appendChild(this.canvas);
        this.canvas.style.width = '100%';
        this.canvasCtx = this.canvas.getContext('2d');
        this.canvasCtx.fillStyle = 'rgb(51, 51, 51)';
        this.canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    methods: {
        handleSliderValueChange: function(value) {
            this.volume = parseFloat(value) / 100;
        },
        handleEnterRoomName: function() {
            // focus out to hide keyboard
            document.getElementById("userId").focus();
        },
        handleEnterUserId: function() {
            document.getElementById("userId").blur();
        },
        writeLog: function(logText) {
            this.$emit("writeLog", logText);
        },
        closeConnection: function(isSendBye) {
            if(isSendBye) {
                // 연결 종료 메시지 전송
                console.log("send bye");
                this.socket.emit("leave", this.roomName, this.userId);
            }
            // 서버에서 bye 메시지를 받으면 상대 불완전 종료. 정보들 초기화.
            this.peerMap.clear();
            this.regenAudioSrcList();

            // 시작 플래그 변경
            this.isStarted = false;
            this.joinFlag = false;

            // 화면꺼짐 방지 해제
            this.noSleep.disable();
        },
        getWaveFileLink: function() {
            const fileName = `${this.userId}_${this.startTimestamp}.webm`;
            let downloadUrl = `waves/${this.roomName}/${fileName}`;
            if(this.$debug) {
                downloadUrl = `https://hmtalk.psymule.com/${downloadUrl}`;
            }
            return {fileName, downloadUrl};
        },
        makeWaveLink: function() {
            const {downloadUrl} = this.getWaveFileLink();
            this.isLinkActive = true;
            this.waveFileLink = downloadUrl;
        },
        showErrorMessage: function(msg) {
            this.errMsg = msg;
            this.showModal = true;
        },
        forceDownloadWave: function() {
            setTimeout(() => {
                const {fileName, downloadUrl} = this.getWaveFileLink();

                this.makeWaveLink();

                // a 링크 만들어서 다운로드
                let a = document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                a.click();
            }, 500);
        },
        registerSocketListener: function() {
            this.socket.on('connect', () => {
                // this.userId = this.$debug ? this.socket.id : "";
            });
            this.socket.on('disconnect', () => {
                if (this.isStarted) {
                    this.writeLog('Disconnected from server');
                    this.showErrorMessage("The connection has been disconnected.\nStopping the recording.");
                    this.stopRecord();
                    this.closeConnection(false);
                    this.makeWaveLink();
                }
            });
            this.socket.on("connect_error", (error) => {
                this.showErrorMessage("Failed to connect to the server.\nPlease try again.");
                this.writeLog(`Failed to connect to the server. ${error.message}`);
                console.log(error);
                this.noSleep.disable();
                this.socket.disconnect();
                this.socket = null;
                this.joinFlag = false;
            });
            this.socket.on('message', (message) => {
                this.writeLog('Client received message :' + message);
                console.log('Client received message :', message);
            });
            this.socket.on('rtc-cmd', (offeredUserId, sessDesc) => {
                if(sessDesc.type === "offer") {
                    // this.writeLog('message type offer');
                    // if (!this.isStarted) {
                    this.connectRTC(offeredUserId);
                    // }
                    let peerConn = this.peerMap.get(offeredUserId).conn;
                    peerConn.setRemoteDescription(new RTCSessionDescription(sessDesc));
                    // doAnswer
                    this.writeLog('Sending answer to ' + offeredUserId);
                    peerConn.createAnswer().then(
                        (sessionDescription) => {
                            this.sendDescription(peerConn, offeredUserId, sessionDescription);
                        },
                        (error) => {
                            this.writeLog('Failed to create session description: ' + error.toString());
                        }
                    )
                }
                else if (sessDesc.type === "answer") {
                    // this.writeLog('message type answer');
                    let peerConn = this.peerMap.get(offeredUserId).conn;
                    peerConn.setRemoteDescription(new RTCSessionDescription(sessDesc));
                }
                else if (sessDesc.type === 'candidate') {
                    // this.writeLog('message type candicate: ' + sessDesc.candidate);
                    const candidate = new RTCIceCandidate({
                        sdpMLineIndex: sessDesc.label,
                        candidate: sessDesc.candidate
                    });
                    let peerConn = this.peerMap.get(offeredUserId).conn;
                    peerConn.addIceCandidate(candidate);
                }
            });
            this.socket.on('created', (room, startTimestamp) => {
                this.writeLog("Created room " + room);
                this.isInitiator = true;
                this.isStarted = true;
                this.startTimestamp = startTimestamp;

                this.writeLog("Room created. Waiting for peers to join...");
            });
            this.socket.on('full', (room) => {
                this.writeLog('Room ' + room + ' is full');
            });
            this.socket.on("join", (roomName, newPeerUserId) => {
                this.writeLog('Another peer made a request to join room ' + roomName);
                this.isChannelReady = true;
                this.writeLog("new peer joined [" + newPeerUserId + "]");
                this.connectRTCWithOffer(newPeerUserId);
            });
            this.socket.on('joined', (room, startTimestamp) => {
                this.writeLog('joined: ' + room);
                this.isChannelReady = true;
                this.isStarted = true;
                this.startTimestamp = startTimestamp;

                this.writeLog("Room joined.")
            });
            this.socket.on("left", (leftUserId) => {
                this.writeLog("peer " + leftUserId + " has left.")
                // rtc 연결 해제
                this.peerMap.get(leftUserId).conn.close();
                // 사용자 목록에서 제거
                this.peerMap.delete(leftUserId);
                // 오디오 목록 재생성
                this.regenAudioSrcList();
                // 나 혼자 남았으면 오디오 레코딩 종료
                // this.stopRecord(false);
            });
            this.socket.on("alreadyJoined", () => {
                this.writeLog("already joined userId: " + this.userId);
                this.noSleep.disable();
                this.socket.disconnect();
                this.socket = null;
                this.showErrorMessage("The user name is already in use.\nPlease enter a different user name.");
            });
            this.socket.on("bye", (isDownloadWave, errMsg) => {
                console.log(`received bye(${isDownloadWave})`);
                this.closeConnection(isDownloadWave);

                if (errMsg) {
                    this.showErrorMessage(errMsg);
                    this.stopRecord();
                }

                if (isDownloadWave && !this.disconAlertFlag) {
                    this.disconAlertFlag = true;
                    this.showErrorMessage("The connection has been disconnected.\nStopping the recording.");

                    this.writeLog("Downloading wave file...");
                    this.stopRecord();

                    setTimeout(() => {
                        const {fileName, downloadUrl} = this.getWaveFileLink();

                        this.makeWaveLink();

                        // a 링크 만들어서 다운로드
                        let a = document.createElement('a');
                        a.href = downloadUrl;
                        a.download = fileName;
                        a.click();
                    }, 500);
                }
            });
        },
        joinFunc: function() {
            if (this.joinFlag) {
                return;
            }

            this.joinFlag = true;
            // 각종 정보 초기화
            this.socket = null;
            this.localStream = null;
            this.recordAudio = null;
            this.audioContext = null;
            this.analyser = null;
            this.dataArray = null;
            this.animationFrameId = null;
            this.rec = null;
            this.input = null;
            this.chunks = [];
            this.timeCode = 0;
            this.isInitiator = false;
            this.isChannelReady = false;
            this.recStartTime = null;
            this.recordingTime = 0;
            this.recordingTimer = null;
            this.startTimestamp = "";
            this.showModal = false;
            this.isLinkActive = false;
            this.waveFileLink = "";
            this.disconAlertFlag = false;
            this.gotStream = false;

            // Form Validation
            if(this.roomName === "" || this.userId === "") {
                alert("Please enter the room name and user name.");
                return;
            }

            // 화면 꺼지기 방지 trick
            this.writeLog("Enable noSleep.")
            this.noSleep.enable();

            this.writeLog("Try to join. please wait...");

            // 소켓 연결
            var targetAddr = location.protocol + "//" + location.hostname + ":" + location.port;
            if(this.$debug) {
                this.socket = io.connect("https://hmtalk.psymule.com", {
                    path: "/socket.io",
                });
            } else {
                this.socket = io(targetAddr, {
                    timeout: 8000,
                });
            }
            this.registerSocketListener();
            this.getStream();
        },
        join: function() {
            this.joinFunc();
        },
        leave: function() {
            // 녹음 종료
            this.stopRecord();
            // 연결 종료
            this.closeConnection(true);
            // 다운로드
            this.forceDownloadWave();
        },
        getStream: function() {
            if (this.gotStream) {
                console.log("already got stream")
                return;
            }

            this.gotStream = true;
            // 디바이스 권한 얻기
            this.getUserMedia((stream) => {
                console.log("getUserMedia success");
                this.localStream = stream;
                // getuserMedia() 성공시 소켓 메세지 날리고
                this.socket.emit("join", this.roomName, this.userId);
            });
        },
        // getUserMedia 기본동작 후 callback 전달
        getUserMedia: function(callbackFunc) {
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: {deviceId: this.audioInputDevice ? {exact: this.audioInputDevice} : undefined}
            })
            .then(callbackFunc)
            .catch((error) => console.error(error));
        },
        connectRTC: function(userId) {
            if (typeof this.localStream !== "undefined" && this.isChannelReady) {
                this.createPeerConnection(userId);
            }
        },
        connectRTCWithOffer: function(userId) {
            this.connectRTC(userId);
            this.writeLog('Sending offer to ' + userId);
            let peerConn = this.peerMap.get(userId).conn;
            peerConn.createOffer({
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 0
            }).then(
                // 성공시
                (sessionDescription) => {
                    this.sendDescription(peerConn, userId, sessionDescription);
                },
                // 실패시
                (event) => {
                    this.writeLog("createOffer() error: " + event);
                }
            )
        },
        createPeerConnection: function(userId) {
            try {
                this.writeLog("createPeerConnection: " + userId);
                let peerConn = new RTCPeerConnection(this.peerConnConfig)
                peerConn.onicecandidate = (event) => {
                    if (event.candidate) {
                        this.socket.emit('rtc-cmd', this.roomName, this.userId, userId, {
                            type: "candidate",
                            label: event.candidate.sdpMLineIndex,
                            id: event.candidate.sdpMid,
                            candidate: event.candidate.candidate,
                        });
                    }
                }
                peerConn.onaddstream = (event) => {
                    // 사용자 목록에 추가하고
                    this.peerMap.get(userId).audioSrc = event.stream;
                    // audio 출력용 리스트 재생성
                    this.regenAudioSrcList();

                    // 녹음 시작
                    this.startRecord();
                }
                peerConn.addStream(this.localStream);

                // 생성한 피어 변수 맵에 저장
                this.peerMap.set(userId, {conn: peerConn, audioSrc: ""});
            }
            catch (e) {
                alert("cannot create RTCPeerConnection object");
            }
        },
        sendDescription: function(peerConn, targetUserId, sessionDescription) {
            peerConn.setLocalDescription(sessionDescription);
            this.socket.emit("rtc-cmd", this.roomName, this.userId, targetUserId, sessionDescription);
        },
        regenAudioSrcList: function() {
            this.audioSrcList = [];
            this.peerMap.forEach((value) => {
                this.audioSrcList.push(value.audioSrc);
            });
        },
        drawWaveform: function() {
            // AnalyserNode에서 데이터 가져오기
            this.analyser.getByteTimeDomainData(this.dataArray);

            // canvas에 그리기
            this.canvasCtx.fillStyle = 'rgb(51, 51, 51)';
            this.canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.lineWidth = 2;
            this.canvasCtx.strokeStyle = '#c86400';
            this.canvasCtx.beginPath();

            const sliceWidth = this.canvas.width * 1.0 / this.analyser.frequencyBinCount;
            let x = 0;

            for(let i = 0; i < this.analyser.frequencyBinCount; i++) {
                const v = this.dataArray[i] / 128.0;
                const y = v * this.canvas.height/2;

                if(i === 0) {
                    this.canvasCtx.moveTo(x, y);
                } else {
                    this.canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            this.canvasCtx.lineTo(this.canvas.width, this.canvas.height/2);
            this.canvasCtx.stroke();

            // 계속해서 파형을 그리도록 요청
            this.animationFrameId = requestAnimationFrame(this.drawWaveform);
        },
        stopWaveform: function() {
            cancelAnimationFrame(this.animationFrameId);
        },
        startRecord: function() {
            if (this.isRecording) {
                console.log("already recording...");
                return;
            }

            this.isRecording = true;

            // 오디오 녹음 시작
            this.mediaRecorder = new MediaRecorder(this.localStream, {
                mimeType: 'audio/webm'
            });

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.socket.emit('audioStream', event.data, this.roomName, this.userId);
                }
            };

            // 파형 출력을 위한 객체들
            this.audioContext = new AudioContext();
            this.input = this.audioContext.createMediaStreamSource(this.localStream);
            this.analyser = this.audioContext.createAnalyser();
            this.input.connect(this.analyser);

            // 파형 데이터 저장할 배열
            this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

            // 파형 그리기 시작
            this.drawWaveform();


            // 최초 시작 메시지 전송
            this.socket.emit("streamStart");

            // Start timers
            this.recordingTime = 0;
            clearInterval(this.recordingTimer);
            this.recordingTimer = setInterval(() => {
                if (!this.recStartTime) {
                    this.recStartTime = Date.now();
                }
                this.recordingTime = Math.floor((Date.now() - this.recStartTime) / 1000);
            }, 100);

            // 녹음 시작
            this.mediaRecorder.start(5000);
            console.log("start recording...");
        },
        stopRecord: function() {
            this.isRecording = false;

            if (this.recordingTimer) {
                // 녹음 중지
                this.mediaRecorder.stop();

                // 파형 그리기 중지
                this.stopWaveform();

                console.log("stop recording...");
                // 타이머 중지
                clearInterval(this.recordingTimer);
                this.recordingTimer = null;
                console.log("recording stopped.");
            }
        },
    }
}
</script>